<template>
  <div class="basic-info">
    <div class="header">
      <div>
        <el-page-header @back="goBack" content="招聘详情"> </el-page-header>
      </div>
    </div>
    <div class="text item">
      <Divider :title="'基本信息'"></Divider>
      <el-row>
        <el-col :span="12" class="text-align"
          >职位名称：{{ detailInfo.name }}</el-col
        >

        <el-col :span="12" class="text-align"
          >招聘人数：{{ detailInfo.number }}人</el-col
        >
        <el-col :span="12" class="text-align"
          >工作地点：{{ detailInfo.location }}，{{
            detailInfo.locationDetail
          }}</el-col
        >

        <el-col :span="12" class="text-align"
          >薪资范围：{{ detailInfo.salaryMinAmount }}~{{
            detailInfo.salaryMaxAmount
          }}元/人·{{ detailInfo.salaryUnit }}</el-col
        >

        <el-col :span="12" class="text-align"
          >福利：{{ detailInfo.welfare }}</el-col
        >

        <el-col :span="24" class="text-align"
          >岗位职责：{{ detailInfo.duty }}</el-col
        >
      </el-row>
      <Divider :title="'岗位要求'"></Divider>
      <el-row>
        <el-col :span="12" class="text-align"
          >学历：{{ detailInfo.educational }}</el-col
        >

        <el-col :span="12" class="text-align"
          >专业：{{ detailInfo.major }}</el-col
        >
        <el-col :span="12" class="text-align"
          >工作年限：{{ detailInfo.minWorkingYears }}</el-col
        >

        <el-col :span="24" class="text-align"
          >岗位要求：{{ detailInfo.requirement }}</el-col
        >
      </el-row>
      <Divider :title="'雇主信息'"></Divider>
      <el-row>
        <el-col :span="12" class="text-align"
          >招聘企业名称：{{ detailInfo.enterpriseName }}</el-col
        >

        <el-col :span="12" class="text-align"
          >联系人：{{ detailInfo.contacts }}</el-col
        >

        <el-col :span="12" class="text-align"
          >联系方式：{{ detailInfo.tel }}</el-col
        >

        <el-col :span="12" class="text-align"
          >关联的合同：{{ detailInfo.linkContractCode }}，{{
            detailInfo.linkContractName
          }}</el-col
        >
      </el-row>
      <Divider :title="'扩散配置'"></Divider>
      <el-row>
        <el-col :span="12" class="text-align"
          >分享海报图片：
          <el-tooltip
            class="item"
            effect="dark"
            content="点击查看图片"
            placement="top-start"
          >
            <i
              class="el-icon-picture-outline font-img"
              @click="showImg(detailInfo.postImage, '查看照片')"
            ></i>
          </el-tooltip>
        </el-col>

        <el-col :span="12" class="text-align"
          >创建时间：{{ detailInfo.createTime }}</el-col
        >

        <!-- <el-col :span="12" class="text-align"
          >是否在banner中显示：{{ detailInfo.isTop }}</el-col
        >

        <el-col :span="12" class="text-align"
          >置顶优先级：{{ detailInfo.topIndex }}</el-col
        >

        <el-col :span="12" class="text-align"
          >是否在banner中显示：{{ detailInfo.isBanner }}</el-col
        > -->

        <!-- <el-col :span="12" class="text-align"
          >banner图片：
          <el-tooltip
            class="item"
            effect="dark"
            content="点击查看图片"
            placement="top-start"
          >
            <i
              class="el-icon-picture-outline font-img"
              @click="showImg(detailInfo.bannerImage, '查看照片')"
            ></i> </el-tooltip
        ></el-col> -->

        <el-col :span="12" class="text-align"
          >备注：{{ detailInfo.remark }}</el-col
        >

        <el-col :span="12" class="text-align"
          >有效期：{{ detailInfo.validityTime }}</el-col
        >

        <!-- <el-col :span="12" class="text-align"
          >状态：{{ detailInfo.state | recruitStateVal }}</el-col
        > -->

        <el-col :span="12" class="text-align"
          >保证金：{{ detailInfo.deposit }}元</el-col
        >
        <!-- <el-col :span="24" class="text-align">招聘内容页：{{ content }}</el-col> -->
      </el-row>
    </div>
    <el-dialog
      class="showImgDialog"
      width="680px"
      :center="true"
      :title="dialogTitle"
      :visible.sync="showImgVisible"
      :append-to-body="true"
    >
      <div class="imgBox">
        <img :src="dialogImgUrl" class="img-resource" alt="" />
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { api } from '/src/api/base';
export const recruitDescribe = api('/recruit')('recruit.describe.json');
export const contentDescribe = api('/content')('content.describe.json');

export default {
  components: {
    Divider: () => import('/src/components/divider.vue')
  },
  data() {
    return {
      code: '',
      detailInfo: {
        code: '',
        name: '',
        number: '',
        location: '',
        duty: '',
        requirement: '',
        educational: '',
        major: '',
        locationDetail: '',
        salaryMinAmount: '',
        salaryMaxAmount: '',
        salaryUnit: '',
        enterpriseName: '',
        contacts: '',
        tel: '',
        welfare: '',
        minWorkingYears: '',
        postImage: '',
        content: '',
        createTime: '',
        isTop: '',
        topIndex: '',
        isBanner: '',
        bannerImage: '',
        remark: '',
        linkContractCode: '',
        linkContractName: '',
        validityTime: '',
        state: '',
        deposit: ''
      },
      dialogTitle: '',
      showImgVisible: false,
      dialogImgUrl: '',
      content: ''
    };
  },

  created() {
    this.code = this.$route.query.code;
    recruitDescribe({ code: this.code }).then(res => {
      this.detailInfo = res;
    });
  },
  methods: {
    async contentDescribe() {
      const res = await contentDescribe({
        code: this.detailInfo.content
      });
      this.content = res.text;
    },
    goBack() {
      window.history.go(-1);
    },
    showImg(img, title) {
      this.dialogImgUrl = img;
      this.dialogTitle = title;
      this.showImgVisible = true;
    },
    dialogClose() {
      this.dialogImgUrl = '';
      this.dialogTitle = '';
      this.showImgVisible = false;
    }
  }
};
</script>

<style lang="scss">
.basic-info .last-breadcrumb {
  color: rgba(0, 0, 0, 0.8);
}
.basic-info .font-img {
  font-size: 26px;
  cursor: pointer;
}
.showImgDialog .imgBox {
  display: flex;
}
.img-resource {
  width: 300px;
  height: 250px;
  margin: 0 auto;
}
.card-header {
  display: flex;
  font-size: 14px;
  align-items: center;
}
.card-icon {
  display: flex;
  width: 4px;
  height: 15px;
  background: #659fe5;
  margin-top: 3px;
  margin-right: 10px;
}
.card-title {
  margin-right: 8px;
}
.card-btn {
  cursor: pointer;
}
.text {
  font-size: 14px;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}
.text-align {
  text-align: left;
  line-height: 32px;
  padding-bottom: 8px;
}
.line-inherit {
  line-height: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.marginBottom {
  margin-bottom: 12px;
}
.statusPointer {
  cursor: pointer;
  margin-right: 12px;
  font-size: 14px;
}
.aType {
  color: #659fe5;
  cursor: pointer;
}
.tooltipClas {
  width: 250px;
}
.basic-info {
  .warning {
    color: #e6a23c;
  }
  .success {
    color: #67c23a;
  }
  .danger {
    color: #f56c6c;
  }
  .info {
    color: #409eff;
  }
}
.defaultTips {
  display: inline-block;
  margin: 0 8px;
  span {
    display: inline-block;
    font-size: 12px;
    color: red;
    line-height: 32px;
    text-align: center;
  }
}
</style>
